import { AfterContentChecked, Component, OnChanges, OnInit } from '@angular/core';
import { MembersPortalService } from '../../angular-wallet-base/services/members-portal.service';
import { MenuController, ModalController, NavController, Platform } from '@ionic/angular';
import { getAuthenticated } from '../../angular-wallet-base/store/wallet';
import { Store } from '@ngrx/store';
import { AppState, getUserPreferencesState } from '../../angular-wallet-base/store/appState';
import { getServiceStatus } from '../../angular-wallet-base/store/connection';
import { openKycModal, openLinkExternal } from '../../angular-wallet-base/utils';
import { environment } from '../../../environments/environment';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { TranslateService } from '@ngx-translate/core';
import { SubscriptionListenerComponent } from '../../angular-wallet-base/SubscriptionListenerComponent';
import { Actions, ofType } from '@ngrx/effects';
import { take, takeUntil } from 'rxjs/operators';
import { ChangeFirstNameAction, UserDataActionTypes } from 'src/app/angular-wallet-base/actions/userData.actions';
import { BeforeActivationComponent } from '../before-activation/before-activation.component';
import { AppModeService } from 'src/app/angular-wallet-base/services/app-mode.service';
import { getActivePage, getSideMenuStatus } from 'src/app/angular-wallet-base/store/appSettings';
import { UpdateSideMenu } from 'src/app/angular-wallet-base/actions/appSettings.actions';
import { TourGuideComponent } from '../tour-guide/tour-guide.component';
import { Logger } from 'src/app/angular-wallet-base/services/logger.service';
import { StorageService } from 'src/app/angular-wallet-base/services/storage.service';
import { INTERCOM_APP_ID, WALLET_STORAGE_KEY, Help_Center_Url, LODE_ID } from 'src/app/angular-wallet-base/constants';
import { SelectAssetModal } from 'src/app/views/select-asset-modal/select-asset-modal.component';
import { Intercom } from '@awesome-cordova-plugins/intercom/ngx';
import { getAccountSetupDetails } from '../../angular-wallet-base/store/accountSetup';
import { InvestorsComponent } from '../investors/investors.component';
import { SelectWalletModalComponent } from 'src/app/views/select-asset-modal/select-wallet-modal/select-wallet-modal.component';
import { OnRamperComponent } from '../on-ramper/on-ramper.component';
import { AuthService } from 'src/app/angular-wallet-base/services/auth.service';
import { ComplianceService } from '../../angular-wallet-base/services/compliance.service';
import { TraceModule } from '@sentry/angular';
import { RoutingService } from '../../angular-wallet-base/services/routing.service';
import { EnvironmentService } from 'src/app/angular-wallet-base/services/environment.service';

declare const window: any;
interface AppLinks {
  id: string;
  title: string;
  icon: string;
  mobile: boolean;
  type: string;
  color?: string;
  queryParams?: {};
}
@Component({
  selector: 'sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
})
export class SideMenuComponent extends SubscriptionListenerComponent implements OnInit, AfterContentChecked {
  options: InAppBrowserOptions = {
    location: 'yes', // Or 'no' 
    hidden: 'no', // Or  'yes'
    clearcache: 'yes',
    clearsessioncache: 'yes',
    zoom: 'yes', // Android only ,shows browser zoom controls 
    hardwareback: 'yes',
    mediaPlaybackRequiresUserAction: 'no',
    shouldPauseOnSuspend: 'no', // Android only 
    closebuttoncaption: 'Close', // iOS only
    toolbar: 'yes', // iOS only 
    toolbarposition: 'top', // iOS only 
    enableViewportScale: 'no', // iOS only 
    allowInlineMediaPlayback: 'no', // iOS only 
    presentationstyle: 'fullscreen', // iOS only 
    fullscreen: 'yes', // Windows only    
  };
  private isValidJwt;
  private loggedIn;
  public lodeid: any = '';
  private isLodeEnabled = environment.features.linkLode;
  private isGasStationEnabled = environment.features.gasStation;
  private isComponentSheetEnabled = environment.features.componentSheet;
  public lodeData = null;
  public serviceStatus = {};
  public hideSideMenu = false;
  public isDesktopMode;
  public activeMenu;
  public vaultStatus = false;
  public showSpinner: boolean = false;

  public socialLinks = [
    {
      id: 'twitter',
      icon: 'logo-twitter',
      type: 'link',
      url: ''
    },
    {
      id: 'plane',
      icon: 'paper-plane-sharp',
      type: 'link',
      url: ''
    },
  ];
  public appLinks: AppLinks[] = [
    {
      id: 'home-page',
      title: 'side_menu.home',
      icon: 'open',
      mobile: true,
      type: 'link',
    },
    {
      id: 'send',
      title: 'side_menu.send',
      icon: 'open',
      mobile: false,
      type: 'link',
    },
    {
      id: 'receive',
      title: 'side_menu.receive',
      icon: 'open',
      mobile: false,
      type: 'link',
    },
    {
      id: 'buy',
      title: 'side_menu.buy',
      icon: 'open',
      mobile: false,
      type: 'link',
    },
    {
      id: 'trade',
      title: 'swap.title',
      icon: 'open',
      mobile: false,
      type: 'link',
    },
    {
      id: 'stake-lode',
      title: 'side_menu.stake',
      icon: 'open',
      mobile: true,
      type: 'link',
    },
    {
      id: 'vest-ltc',
      title: 'side_menu.vest',
      icon: 'open',
      mobile: true,
      type: 'link',
    },
    {
      id: 'debitcard',
      title: 'side_menu.debitcard_active',
      icon: 'open',
      mobile: true,
      type: 'link',
    },
    {
      id: '',
      title: '',
      icon: '',
      mobile: true,
      type: 'seperator',
    },
    {
      id: 'faq',
      title: 'side_menu.faq',
      icon: 'open',
      mobile: true,
      type: 'link',
    },
    {
      id: '',
      title: '',
      icon: '',
      mobile: true,
      type: 'seperator',
    },
    {
      id: 'profile',
      title: 'side_menu.edit_profile',
      icon: 'open',
      mobile: true,
      type: 'link',
    },
    {
      id: 'settings',
      title: 'side_menu.settings',
      icon: 'open',
      mobile: true,
      type: 'link',
    },
    {
      id: 'contact',
      title: 'side_menu.contact',
      icon: 'open',
      mobile: true,
      type: 'link',
    },
  ];
  public appPages = [];
  public userdetails: any;
  public totalAssetBalance = 0;
  public isSendDisabled = true;

  constructor(
    private membersPortalService: MembersPortalService,
    protected store: Store<AppState>,
    private storage: StorageService,
    private actions$: Actions,
    private inAppBrowser: InAppBrowser,
    private translate: TranslateService,
    private platform: Platform,
    private menuCtrl: MenuController,
    private modalCtrl: ModalController,
    private appMode: AppModeService,
    private nav: NavController,
    public intercom: Intercom,
    private routingService: RoutingService,
    private authService: AuthService,
    private complianceService: ComplianceService,
    private environmentService: EnvironmentService
  ) {
    super(store);
    this.store.select(getAuthenticated).subscribe(loggedIn => {
      if (!loggedIn) {
        return;
      }
    });
    this.store.select(getServiceStatus).subscribe(statuses => {
      this.serviceStatus = statuses;
    });
    this.appMode.isDesktopMode.subscribe(mode => this.isDesktopMode = mode);
    this.store.select(getUserPreferencesState).subscribe(this.handleLodeDataLoad.bind(this));
    // this.userdetails = this.store.select(getAccountSetupDetails).toPromise();
    this.store.select(getActivePage).subscribe(activePage => {
      this.activeMenu = activePage;
      this.setSideMenu();
    });
    this.store.select(getSideMenuStatus).subscribe(sideMenu => {
      this.hideSideMenu = sideMenu;
    });

    this.storage.totalBalance.subscribe(balance => {
      this.totalAssetBalance = balance;
    });
  }
  
  ngAfterContentChecked(): void {
    if (this.totalAssetBalance === 0 && this.vaultStatus) {
      this.isSendDisabled = false;
    } else {
      this.isSendDisabled = false;
    }
  }

  async ngOnInit() {
    this.addShadowPartToMenu();
    this.vaultStatus = (await this.storage.get(WALLET_STORAGE_KEY)) ? true : false;
    let lodeId = await this.storage.get(LODE_ID);
    this.intializeIntercom(lodeId);
  }


  intializeIntercom(lodeId) {
    if (this.platform.is('cordova')) {
      this.intercom.registerIdentifiedUser({ userId: lodeId });
    } else {
      window.Intercom('boot', {
        app_id: INTERCOM_APP_ID,
        user_id: lodeId,
        hide_default_launcher: true
      });
    }
  }

  ngOnDestroy(): void {
    this.intercom.logout();
  }

  addShadowPartToMenu() {
    // ion-menu has som shadow DOM elements that we need to apply css to. 
    // There is no way to access to those elements without dirty tricks
    // in this Ionic version (v4 has shadow parts).
    const ionMenu = document.querySelector('ion-menu');

    if (ionMenu.shadowRoot.firstElementChild) {
      ionMenu.shadowRoot.firstElementChild.setAttribute('part', 'container');
    } else {
      setTimeout(() => this.addShadowPartToMenu(), 200);
    }
  }

  async setSideMenu() {
    const appPages = [];
    const debitCardStatus = true; // update using ngrx or behaviour subject

    //check validity of user for wallet connect link
    if(await this.authService.hasGroup('GROUP_WALLETCONNECT') && this.vaultStatus) {
      //if the link doesn't exist, add it
      if(!this.appLinks.find(link => link.id === "wallet_connect")) {
        //add wallet connect link to side menu
        this.appLinks.push(
          { id: "wallet_connect", 
            title: "link_labels.wallet_connect", 
            icon: "open", 
            mobile: true, 
            type: "link" 
          }
        );
      }
    }

    this.appLinks.forEach((link) => {
      link.color = 'light';
      if (link.id === this.activeMenu) {
        link.color = 'secondary';
      }
      if (link.id === 'debitcard' && !debitCardStatus) {
        link.title = 'side_menu.debitcard_inactive';
        link.color = 'warning';
      }
      link.queryParams = {};
      appPages.push(link);
    });

    this.appPages = appPages;
  }


  disableSendTab(page) {

    if (page?.id === 'send' && this.totalAssetBalance === 0 && this.vaultStatus) {
      return true;
    } else {
      return false;
    }
  }

  async navigateTo(page) {
    await this.menuCtrl.close();
    const menuPosition = document.getElementById('menu-' + this.appPages.indexOf(page));
    const menuHeight = menuPosition.getBoundingClientRect().top;
    this.vaultStatus = (await this.storage.get(WALLET_STORAGE_KEY)) ? true : false;
    const showBeforeActivationModal = !this.vaultStatus && (page.id !== this.activeMenu) && this.isDesktopMode;

    // close if any modal opened
    await this.dismissModal();
         this.activeMenu = page.id;
       this.setSideMenu()
       
    // TODO: replace Logger with apt navigation url
    switch (page.id) {
      case 'send':
        (showBeforeActivationModal)
          ? await this.befofeActivationModal(page, menuHeight)
          : await this.presentModal(page.id);
        break;
      case 'receive':
        (showBeforeActivationModal)
          ? await this.befofeActivationModal(page, menuHeight)
          : await this.presentModal(page.id);
        break;
      case 'trade':
        (showBeforeActivationModal)
          ? await this.befofeActivationModal(page, menuHeight)
          : await this.nav.navigateForward('trade');
        break;
      case 'stake-lode':
         await this.nav.navigateRoot('stake-lode');
        break;
       case 'vest-ltc':
         await this.nav.navigateRoot('vest-ltc');
        break;
      case 'buy':

        (showBeforeActivationModal)
          ? await this.befofeActivationModal(page, menuHeight)
          // : await this.openSelectWalletModal(page.id)
          : await this.presentModal(page.id);
        break;
      case 'home-page':
        await this.routingService.goHome();
        break;
      case 'settings':
        await this.nav.navigateRoot('wallet/settings');
        break;
      case 'profile':
        await this.nav.navigateRoot('edit-profile');
        break;
      case 'faq':
        this.toFaq();
        break;
      case 'investors':
        this.toInvestors();
        break;
      case 'contact':
        this.toContact();
        break;
      case "wallet_connect":
        this.navigateToWalletConnect();
        break;
      case 'debitcard':
        await this.nav.navigateRoot('my-cards');
        break;
      default:
        return;
      }
  }
      
  toFaq() {
    this.openLinkExternal('faqPage')
  }
  
  @AuthService.requireGroup('GROUP_WALLETCONNECT')
  async navigateToWalletConnect() {
    await this.nav.navigateRoot("wallet-connect");
  }

  async toInvestors() {
    this.platform.ready().then(async () => {
      if (this.platform.is('cordova')) {
        const url = "https://issuu.com/lodecommunity/docs/lode_made_easy.v3.7?fr=sZGMwZDQzMDk3OTc"
        openLinkExternal(url, this.inAppBrowser, this.options);
      } else {
        const sideMenuHidden = await this.store.select(getSideMenuStatus).pipe(take(1)).toPromise();
        let cssClass = '';
        cssClass += (this.isDesktopMode) ? 'investors-modal-desktop' : 'update-password-modal';
        cssClass += (!sideMenuHidden && this.isDesktopMode) ? ' with-side-menu' : '';

        const modal = await this.modalCtrl.create({
          component: InvestorsComponent,
          cssClass,
        });
        await modal.present();
      }
    }).catch((error) => {
      Logger.info('Investors not loaded', error)
    });

  }

  toContact() {
    this.showSpinner = true;
    if (this.platform.is('cordova')) {
      this.intercom.displayMessenger()
        .catch((error) => Logger.info('intercom failed !!', error))
        .finally(() => {
          this.showSpinner = false;
        });
    } else {
      window.Intercom('show');
      this.showSpinner = false;
    }
  }

  async openSelectWalletModal(mode) {
    const sideMenuHidden = await this.store.select(getSideMenuStatus).pipe(take(1)).toPromise();
    let cssClass = "";
    cssClass += this.isDesktopMode ? "select-asset-modal-desktop" : "select-asset-modal";
    cssClass += !sideMenuHidden && this.isDesktopMode ? " with-side-menu" : "";
    const modal = await this.modalCtrl.create({
      component: SelectWalletModalComponent,
      componentProps: {
        // sendOrReceiveOrBuy: mode,
      },
      cssClass,
      initialBreakpoint: this.isDesktopMode ? undefined : 0.5,
      breakpoints: this.isDesktopMode ? undefined : [0, 0.6, 0.9, 1],
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      this.presentModal(data.type);
    }

  }

  async presentModal(mode: string) {
    const sideMenuHidden = await this.store.select(getSideMenuStatus).pipe(take(1)).toPromise();

    if (mode == 'on-ramper') {
      let cssClass = '';
      cssClass += (this.isDesktopMode) ? 'update-password-modal-desktop' : 'update-password-modal';
      cssClass += (!sideMenuHidden && this.isDesktopMode) ? ' with-side-menu' : '';
      const modal = await this.modalCtrl.create({
        component: OnRamperComponent,
        cssClass
      });
      await modal.present();
      return;
    }

    if (mode == "buy") {
      var kycData = await this.complianceService.getKycCip();
      if (!kycData["passingLevel"]) {
        await openKycModal(sideMenuHidden, this.isDesktopMode, this.modalCtrl);
        return;
      }
    }

    let cssClass = "";
    cssClass += this.isDesktopMode ? "select-asset-modal-desktop" : "select-asset-modal";
    cssClass += !sideMenuHidden && this.isDesktopMode ? " with-side-menu" : "";
    const modal = await this.modalCtrl.create({
      component: SelectAssetModal,
      componentProps: {
        actionType: mode,
        // newMember: this.applyPromo == true ? true : false,
        // promocode: this.applyPromo == true ? this.newMemberOfferData.attributes.code : null
      },
      cssClass,
      id: 'select-asset-model',
      initialBreakpoint: this.isDesktopMode ? undefined : 0.9,
      breakpoints: this.isDesktopMode ? undefined : [0, 0.6, 0.9, 1],
    });
    await modal.present();
  }

  // show activatiom modal if wallet not activated yet
  async befofeActivationModal(page, menuHeight) {
    return;// hiden show beforeActivation quick tour CORE-5959
    await this.dismissModal();
    const modal = await this.modalCtrl.create({
      component: BeforeActivationComponent,
      mode: 'ios',
      cssClass: 'before-activation-desktop',
      backdropDismiss: true,
      componentProps: { currentPage: page.id, type: 'modal', menuHeight }
    });
    await modal.present();
    await modal.onWillDismiss().then((response) => {
      if (response.data !== undefined) {
        if (response.data.link) {
          this.nav.navigateForward(response.data.link);
        }
      }
    });
  }

  async presentTourGuide() {
    const modal = await this.modalCtrl.create({
      component: TourGuideComponent,
      cssClass: 'tour-guide-modal',
      showBackdrop: true,
    });
    return await modal.present();
  }

  async handleLodeDataLoad(userPreferences) {
    if (!userPreferences || !userPreferences.jwtToken) {
      this.isValidJwt = false;
      this.lodeid = '';
    } else {
      this.lodeid = userPreferences.lodeid;
      this.isValidJwt = await this.membersPortalService.isJwtValid(userPreferences.jwtToken);
    }
    this.getLodeData();
    this.subscriptions.add(this.actions$.pipe(
      ofType(UserDataActionTypes.CHANGE_FIRST_NAME),
      takeUntil(this.destroyed$))
      .subscribe(async (action: ChangeFirstNameAction) => {
        if (action.payload) {
          this.lodeData.fname = action.payload;
        }
      }));
    await this.setSideMenu();
  }

  public async getLodeData() {
    try {
      this.lodeData = await this.membersPortalService.getUserInfo();
    } catch (err) {
      this.lodeData = null;
    }
  }

  public async openLinkExternal(type, id?) {
    const url = type == 'faqPage' ?
      Help_Center_Url
      : await this.translate.get(`link_labels.links.${id}`).toPromise();
    openLinkExternal(url, this.inAppBrowser, this.options);
  }

  public generateAvatarUrl() {
    const avatar = this.lodeData.fname ? this.lodeData.fname.replace(/\s/g, '+') : 'Lode';
    return 'https://avatar.oxro.io/avatar.svg?&background=687984&color=fff&name=' + avatar;
  }

  public toggleSideMenu() {
    this.store.dispatch(new UpdateSideMenu(!this.hideSideMenu));
    this.menuCtrl.close();
    this.dismissModal();
  }

  async dismissModal() {
    try {
      await this.modalCtrl.dismiss();
    } catch (error) { }
  }
}
